import React from 'react'

const FaqSection = () => {
    return (
        <div>
            <section className="faq-section" id="section-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="heading-blk">
                                <h2>Frequently Asked Questions !</h2>
                            </div>
                        </div>
                        <div className="col-lg-10">
                            <div className="faq-wrapper">
                                <div className="accordion" id="faqAccordion">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqOne" aria-expanded="true" aria-controls="faqOne">
                                                How is Lumakin different from genealogy sites?
                                            </button>
                                        </h2>
                                        <div id="faqOne" className="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p>Most genealogy sites are focused on historical data. Lumakin has a centralized focus on documenting your stories for future generations.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqTwo" aria-expanded="false" aria-controls="faqTwo">
                                                How is Lumakin different from social media sites?
                                            </button>
                                        </h2>
                                        <div id="faqTwo" className="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p>Lumakin isn't a place for memes or influencers. It is a place to document your life's story through text, audio and video. The purpose is to share events from your life on a timeline so your family and future generations have an authentic version of who you are. They will treasure the time you took sharing entries during your life and once you have passed. Both founders have lost close family members and wished that we had their voice somewhere. We've all kept that voicemail for years after someone passes, but this is so much more of their life's story from their perspective. This is a way to immortalize your life and your story.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqThree" aria-expanded="false" aria-controls="faqThree">
                                                Can I send my mom a list of questions to answer?
                                            </button>
                                        </h2>
                                        <div id="faqThree" className="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p>Yes! You can send anyone questions and decide how you want them to respond (text, audio, video). You can ask your spouse to list ten things they love about you and have it on video for all time where only the two of you can see it if you wish..  </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqFour" aria-expanded="false" aria-controls="faqFour">
                                                Can I have my mom record herself singing the song she sang to us growing up?
                                            </button>
                                        </h2>
                                        <div id="faqFour" className="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p>Yes! You can ask anything and request a video so that when your mom passes, you have this treasured video forever. </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqFive" aria-expanded="false" aria-controls="faqFive">
                                                Do we have to share all of our entries with the public?
                                            </button>
                                        </h2>
                                        <div id="faqFive" className="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p>No! You get to pick who and what you want to share. If you want to document finding out you are having a baby, but not ready to share with the world, make private entries, make new entries when you want to share with friends or family, and make public entries when ready. You choose who gets to see each and every entry. </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqSix" aria-expanded="false" aria-controls="faqSix">
                                                Can I choose which journal entries or interviews I want to share publicly?
                                            </button>
                                        </h2>
                                        <div id="faqSix" className="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p>Yes! You can do fun interviews that you want to share with certain circles or the public and make entries that are only for you to see and review in your timeline. </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqSeven" aria-expanded="false" aria-controls="faqSeven">
                                                Can I add a boyfriend to my family tree/ timeline?
                                            </button>
                                        </h2>
                                        <div id="faqSeven" className="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p>Yes, you can add people to your family tree in several areas of life and even have it reflect on the timeline. Show who was in your family each year and watch it change as each year passes</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqEight" aria-expanded="false" aria-controls="faqEight">
                                                Can Lumakin help me forget my embarrassing dance moves at last year’s party?
                                            </button>
                                        </h2>
                                        <div id="faqEight" className="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p>Nope! But we can help you immortalize them for generations to enjoy your smooth(ish) moves! 🕺💃</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqNine" aria-expanded="false" aria-controls="faqNine">
                                                What if my life’s story is just memes?
                                            </button>
                                        </h2>
                                        <div id="faqNine" className="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p>Perfect! Future generations need to understand the art of memes. Curate your meme-tastic life journey with Lumakin!</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqTen" aria-expanded="false" aria-controls="faqTen">
                                                Can I save the tale of my misadventure involving a pizza, a squirrel, and my wig?
                                            </button>
                                        </h2>
                                        <div id="faqTen" className="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p>Absolutely! Lumakin supports all tales of culinary escapades and unexpected wildlife interactions. 🍕🐿️💇</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqEleven" aria-expanded="false" aria-controls="faqEleven">
                                                Will Lumakin prevent my friends from exaggerating my stories after I’m gone?
                                            </button>
                                        </h2>
                                        <div id="faqEleven" className="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p>We can’t stop the fish tales, but we can sure help your authentic voice shine through amidst the tall tales!</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqTwelve" aria-expanded="false" aria-controls="faqTwelve">
                                                My grandma tells the same story differently each time; can Lumakin keep up?
                                            </button>
                                        </h2>
                                        <div id="faqTwelve" className="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p>Lumakin loves every version! Capture them all and let the family decide which epic rendition wins! 🏆</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqThirteen" aria-expanded="false" aria-controls="faqThirteen">
                                                Can I create a timeline of just my pet’s shenanigans?
                                            </button>
                                        </h2>
                                        <div id="faqThirteen" className="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p>A resounding yes! Let's immortalize Fluffy’s reign of adorable terror for eternity! 🐶🐱👑</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqFourteen" aria-expanded="false" aria-controls="faqFourteen">
                                                What if my autobiography accidentally becomes a cookbook?
                                            </button>
                                        </h2>
                                        <div id="faqFourteen" className="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p>Lumakin supports all flavors of life stories! Mix those tales and recipes and voila, a delicious memoir! 📖🍲</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqFifteen" aria-expanded="false" aria-controls="faqFifteen">
                                                Will Lumakin judge me for my awkward teenage phase?
                                            </button>
                                        </h2>
                                        <div id="faqFifteen" className="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p>Never! Lumakin is your judgement-free zone, where all phases (even the cringe-y ones) are celebrated with open arms! 🎉🤓</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqSixteen" aria-expanded="false" aria-controls="faqSixteen">
                                                Can Lumakin handle the epic tale of my failed attempts at DIY?
                                            </button>
                                        </h2>
                                        <div id="faqSixteen" className="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p>Bring it on! Your botched birdhouses and lopsided lamps are treasures waiting to be archived!</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqSeventeen" aria-expanded="false" aria-controls="faqSeventeen">
                                                Does Lumakin offer therapy for reliving my embarrassing moments while documenting?
                                            </button>
                                        </h2>
                                        <div id="faqSeventeen" className="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p>While we're expert story preservers, therapy is above our pay grade! But hey, laughter is the best medicine, right? 🤣👩‍⚕️📚</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="rich-text">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="rich-content">
                                <h2>Enjoy crafting and exploring the endless, tear-filled, hilarious, or potentially blush-worthy possibilities with Lumakin!</h2>
                                <h2>Let’s keep those authentic stories rolling through the generations!</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default FaqSection;
