import { useEffect } from "react";
import "./App.css";
import Footer from "./components/footer";
import Header from "./components/header";
import HomeScreen from "./screens/homeScreen";
import "aos/dist/aos.css";
import AOS from "aos";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Toaster } from "react-hot-toast";
import Navigation from "./routes/navigations";
import MainScreen from "./screens/homeScreen";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <Toaster
        position="top-right"
        gutter={10}
        containerClassName="p-5"
        containerStyle={{ padding: "20px" }}
        toastOptions={{
          duration: 5000,
          style: {
            padding: "10px 50px",
            fontWeight: "600",
          },
        }}
      />
      {window.location.pathname === "/waitlist" ||
      "/privacy-policy" ||
      "/roots-tech" ||
      "/thank-you" ? (
        <Navigation />
      ) : (
        <>
          <Header />
          <MainScreen />
          <Footer />
        </>
      )}
      <ToastContainer
        icon={({ type }) => {
          if (type === "success") return <img src="./favicon.png" alt="..." />;
          if (type === "error") return null;
          else return "ℹ️";
        }}
        progressStyle={{ backgroundColor: "#30C6CC" }}
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
