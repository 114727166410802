import React from "react";
import { Route, Routes } from "react-router-dom";
import Waitlist from "../screens/waitlist";
import Header from "../components/header";
import Footer from "../components/footer";
import MainScreen from "../screens/homeScreen";
import PrivacyPolicyPage from "../components/privacyPolicyPage";

import ThankyouPage from "../components/thankyouPage";
import RootsTechPage from "../components/rootsTechPage";

const Navigation = () => {
  return (
    <Routes>
      <Route path="/waitlist" element={<Waitlist />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route path="/roots-tech" element={<RootsTechPage />} />
      <Route path="/thank-you" element={<ThankyouPage />} />
      <Route
        path="/*"
        element={
          <>
            <Header />
            <MainScreen />
            <Footer />
          </>
        }
      />
    </Routes>
  );
};

export default Navigation;
