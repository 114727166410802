/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { BasicPriceArray, PremiumPriceArray } from "../seeds";
import { calculateIntegerDecimal, convertPlan } from "../utils/helpers";
import { useNavigate } from "react-router-dom";

const PricingSection = () => {
  const [selectedPrice, setSelectedPrice] = useState("1");
  const nav = useNavigate()

  const [selectedPremiumData, setSelectedPremiumData] = useState(
    PremiumPriceArray[0]
  );
  const [selectedBasicData, setSelectedBasicData] = useState(
    BasicPriceArray[0]
  );

  const {
    integerNumber: integerPremiumNumber,
    decimalNumber: decimalPremiumNumber,
  } = calculateIntegerDecimal(selectedPremiumData?.amount);

  const {
    integerNumber: integerBasicNumber,
    decimalNumber: decimalBasicNumber,
  } = calculateIntegerDecimal(selectedBasicData?.amount);

  const handleRadioChange = (event) => {
    let num = Number(event.target.id);
    setSelectedPremiumData(PremiumPriceArray[num - 1]);
    setSelectedBasicData(BasicPriceArray[num - 1]);
    setSelectedPrice(event.target.id);
  };
  const handleOpenWaitlist = ()=>{
      nav("/waitlist")
      window.location.reload()
  }
  return (
    <div>
      <section className="pricing-section" id="section-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="heading-blk">
                <h2>Pricing</h2>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="pricing-wrapper">
                <div className="pricing-content">
                  <div className="row justify-content-center">
                    <div className="col-xxl-12">
                      <div className="pricing-card-wrapper">
                        <div
                          className="pricing-card"
                          data-aos="fade-down-right"
                          data-aos-duration="1500">
                          <div className="pricing-card-header">
                            <h3>FREE 7-DAY TRIAL</h3>
                          </div>
                          <div className="pricing-card-body">
                            <div className="price-blk">
                              <span className="amount">$0</span>
                            </div>
                            <div className="plan-feature-detail">
                              <div className="card-feature-blk">
                                <ul className="card-feature-list">
                                  <li>
                                    5 GB Storage span.
                                    <span className="card-feature-info">
                                      Upto 12 months Storage
                                    </span>
                                  </li>
                                  <li>Relationship Tree</li>
                                  <li>Events</li>
                                  <li>Journaling</li>
                                  <li>Interviewing</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="pricing-card-footer" onClick={handleOpenWaitlist}>
                            <a
                              className="pricing-card-btn bg-secondary-clr"
                              >
                              <span className="text-icon">
                                Join for Free
                                <i className="fa-solid fa-chevron-right" />
                              </span>
                            </a>
                          </div>
                        </div>

                        <div
                          className="pricing-card"
                          data-aos="fade-down"
                          data-aos-duration="1500">
                          <div className="pricing-card-header">
                            <h3>basic</h3>
                          </div>
                          <div className="pricing-card-body">
                            <div className="price-blk">
                              <span className="amount">
                                ${integerBasicNumber}
                                <sup>
                                  <span className="float-num">
                                    .{decimalBasicNumber}
                                  </span>
                                  <span className="plan-period">
                                    /{selectedBasicData?.plan}
                                  </span>
                                </sup>
                              </span>
                              <div className="pricing-differ-blk">
                                {BasicPriceArray?.map((item, index) => (
                                  <>
                                    <div class="select-price">
                                      <span class="select-price-field">
                                        <input
                                          type="radio"
                                          name="basic"
                                          id={item.id}
                                          checked={selectedPrice === item.id}
                                          onChange={handleRadioChange}
                                          onClick={() =>
                                            setSelectedBasicData(item)
                                          }
                                        />
                                        <label for={item?.id}>
                                          <span class="radio-circle"></span>
                                          <span class="amount-wrapper">
                                            {index === 1 ? (
                                              <span class="actual-amount-blk">
                                                <span class="actual-amount">
                                                  USD ${item?.oldAmount}
                                                </span>
                                                <span class="select-period">
                                                  /yr
                                                </span>
                                              </span>
                                            ) : null}
                                            <span class="actual-amount-blk">
                                              <span class="offer-price">
                                                USD ${item?.amount}
                                              </span>
                                              <span class="select-period">
                                                /{convertPlan(item?.plan)}
                                              </span>
                                            </span>
                                          </span>
                                        </label>
                                      </span>
                                    </div>
                                  </>
                                ))}
                                <span className="f-month">2 Months Free</span>
                              </div>
                            </div>

                            <div className="plan-feature-detail">
                              <span class="every-text">
                                Everything in Free 7-Day Trial, plus:
                              </span>
                              <div className="card-feature-blk">
                                <ul className="card-feature-list">
                                  <li>
                                    200 GB Storage
                                    <span className="card-feature-info">
                                      plus $1.99/month every add'l 100 GB
                                    </span>
                                  </li>
                                  <li>Custom Circles</li>
                                  <li>Collaboration</li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="pricing-card-footer" onClick={handleOpenWaitlist}>
                            <a
                              className="pricing-card-btn bg-dark-clr"
                            >
                              <span className="text-icon">
                                Get Basic
                                <i className="fa-solid fa-chevron-right" />
                              </span>
                            </a>
                          </div>
                        </div>

                        <div
                          className="pricing-card"
                          data-aos="fade-down-left"
                          data-aos-duration="1500">
                          <div className="pricing-card-header">
                            <h3>premium</h3>
                          </div>
                          <div className="pricing-card-body">
                            <div className="price-blk">
                              <span className="amount">
                                ${integerPremiumNumber}
                                <sup>
                                  <span className="float-num">
                                    .{decimalPremiumNumber}
                                  </span>
                                  <span className="plan-period">
                                    /{selectedPremiumData?.plan}
                                  </span>
                                </sup>
                              </span>

                              <div className="pricing-differ-blk">
                                {PremiumPriceArray?.map((item, index) => (
                                  <>
                                    <div class="select-price">
                                      <span class="select-price-field">
                                        <input
                                          type="radio"
                                          name="premium"
                                          id={item.id}
                                          checked={selectedPrice === item.id}
                                          onChange={handleRadioChange}
                                          onClick={() =>
                                            setSelectedPremiumData(item)
                                          }
                                        />
                                        <label for={item?.id}>
                                          <span class="radio-circle"></span>
                                          <span class="amount-wrapper">
                                            {index === 1 ? (
                                              <span class="actual-amount-blk">
                                                <span class="actual-amount">
                                                  USD ${item?.oldAmount}
                                                </span>
                                                <span class="select-period">
                                                  /yr
                                                </span>
                                              </span>
                                            ) : null}
                                            <span class="actual-amount-blk">
                                              <span class="offer-price">
                                                USD ${item?.amount}
                                              </span>
                                              <span class="select-period">
                                                /{convertPlan(item?.plan)}
                                              </span>
                                            </span>
                                          </span>
                                        </label>
                                      </span>
                                    </div>
                                  </>
                                ))}
                                <span className="f-month">2 Months Free</span>
                              </div>
                            </div>
                            <div className="plan-feature-detail">
                              <span className="every-text">
                                Everything in basic, plus:
                              </span>
                              <div className="card-feature-blk">
                                <ul className="card-feature-list">
                                  <li>
                                    Share Storage
                                    <span className="card-feature-info">
                                      Upto 6 Persons
                                    </span>
                                  </li>
                                  <li>
                                    6 TB Storage
                                    <span className="card-feature-info">
                                      plus $1.99/month every add'l 100 GB
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="pricing-card-footer" onClick={handleOpenWaitlist}>
                            <a
                              className="pricing-card-btn bg-secondary-clr"
                              >
                              <span className="text-icon">
                                Get Premium
                                <i className="fa-solid fa-chevron-right" />
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="compare-section" id="section-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="heading-blk">
                <h2>Compare All Plans</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-10">
              <div className="compare-wrapper">
                <table>
                  <thead>
                    <tr>
                      <th />
                      <th>
                        <span className="plan-heading-free">
                          FREE 7-DAY TRIAL
                        </span>
                      </th>
                      <th>
                        <span className="plan-heading-basic">BASIC</span>
                      </th>
                      <th>
                        <span className="plan-heading-pre">premium</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>price</td>
                      <td>
                        <span className="plan-amount">Free</span>
                      </td>
                      <td>
                        <span className="plan-amount">
                          $6.99<span class="select-period">/Mo</span>
                        </span>
                      </td>
                      <td>
                        <span className="plan-amount">
                          $299.90<span class="select-period">/Yr</span>
                        </span>
                        <span className="saving-txt">(2 Months Free)</span>
                      </td>
                    </tr>

                    <tr>
                      <td>Storage</td>
                      <td>
                        <i className="fa-solid fa-check" />
                      </td>
                      <td>
                        <i className="fa-solid fa-check" />
                      </td>
                      <td>
                        <i className="fa-solid fa-check" />
                      </td>
                    </tr>
                    <tr>
                      <td>Relationship Tree</td>
                      <td>
                        <i className="fa-solid fa-check" />
                      </td>
                      <td>
                        <i className="fa-solid fa-check" />
                      </td>
                      <td>
                        <i className="fa-solid fa-check" />
                      </td>
                    </tr>
                    <tr>
                      <td>Events</td>
                      <td>
                        <i className="fa-solid fa-check" />
                      </td>
                      <td>
                        <i className="fa-solid fa-check" />
                      </td>
                      <td>
                        <i className="fa-solid fa-check" />
                      </td>
                    </tr>
                    <tr>
                      <td>Journaling</td>
                      <td>
                        <i className="fa-solid fa-check" />
                      </td>
                      <td>
                        <i className="fa-solid fa-check" />
                      </td>
                      <td>
                        <i className="fa-solid fa-check" />
                      </td>
                    </tr>
                    <tr>
                      <td>Interviewing</td>
                      <td>
                        <i className="fa-solid fa-check" />
                      </td>
                      <td>
                        <i className="fa-solid fa-check" />
                      </td>
                      <td>
                        <i className="fa-solid fa-check" />
                      </td>
                    </tr>
                    <tr>
                      <td>Custom Circles</td>
                      <td>
                        <i className="fa-solid fa-xmark" />
                      </td>
                      <td>
                        <i className="fa-solid fa-check" />
                      </td>
                      <td>
                        <i className="fa-solid fa-check" />
                      </td>
                    </tr>
                    <tr>
                      <td>Collaboration</td>
                      <td>
                        <i className="fa-solid fa-xmark" />
                      </td>
                      <td>
                        <i className="fa-solid fa-check" />
                      </td>
                      <td>
                        <i className="fa-solid fa-check" />
                      </td>
                    </tr>
                    <tr>
                      <td>Share Storage</td>
                      <td>
                        <i className="fa-solid fa-xmark" />
                        <button
                          onClick={handleOpenWaitlist}
                          className="table-btn-position get-free">
                          Get free 7-day trial
                        </button>
                      </td>
                      <td>
                        <i className="fa-solid fa-xmark" />
                        <button
                          onClick={handleOpenWaitlist}
                          className="table-btn-position get-basic">
                          Get Basic
                        </button>
                      </td>
                      <td>
                        <i className="fa-solid fa-check" />
                        <button
                          onClick={handleOpenWaitlist}
                          className="table-btn-position get-pre">
                          Get Premium
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PricingSection;
