import React from 'react';
import { ClipLoader } from 'react-spinners';

const Modal = ({
    children,
    modalTitle,
    closeButtonTitle,
    actionButtonTitle,
    closeButtonFn,
    actionButtonFn,
    isActionButtonNotRequire,
    actionClass,
    open,
    modalSizeClass,
    loading,
    style
}) => {

    if (!open) {
        return null
    }
    else {
        return (
            <div className='modal-overlay'>
                <div className="modal fade show d-block" tabIndex={-1}
                    aria-hidden="true">
                    <div className={`modal-dialog modal-dialog-centered ${modalSizeClass}`} role="document">
                        <div className="modal-content" style={{ background: "#1a1d34", color: "white" }} >
                            <div className="modal-header">
                                <h5 className="modal-title">{modalTitle}</h5>
                                <img width={30} onClick={closeButtonFn} className='cross-icon' src='/assets/images/cross-small.svg' alt='' />
                            </div>
                            <div className="modal-body">
                                {children}
                            </div>
                            <div className="modal-footer static-padding">
                                <button type="button" onClick={closeButtonFn} className="btn btn-secondary" data-dismiss="modal">
                                    {closeButtonTitle}
                                </button>
                                {
                                    !isActionButtonNotRequire
                                    && <button type="button" onClick={actionButtonFn} className={actionClass}>
                                        {loading ? <ClipLoader size={12} color="#fff" /> : actionButtonTitle}
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Modal;