export const RoutingNavigation = [
  {
    name: "Home",
    id: "section-1",
    path: "/",
  },
  {
    name: "Why Us",
    id: "section-2",
    path: "/why-us",
  },
  {
    name: "Features",
    id: "section-3",
    path: "/features",
  },
  {
    name: "Pricing",
    id: "section-4",
    path: "/pricing",
  },
  {
    name: "FAQ",
    id: "section-5",
    path: "/faq",
  },
  {
    name: "Contact Us",
    id: "section-6",
    path: "/contact-us",
  },
];

export const PremiumPriceArray = [
  {
    id: "1",
    amount: "29.99",
    oldAmount: "359.88",
    plan: "Monthly",
  },
  {
    id: "2",
    amount: "299.90",
    oldAmount: "359.88",
    plan: "Yearly",
  },
];

export const BasicPriceArray = [
  {
    id: "1",
    amount: "6.99",
    oldAmount: "399.90",
    plan: "Monthly",
  },
  {
    id: "2",
    amount: "69.99",
    oldAmount: "83.88",
    plan: "Yearly",
  },
];

export const FeaturesArray = [
  {
    id: 1,
    title: "Platform for personal stories ",
  },
  {
    id: 2,
    title: "Digitize your life",
  },
  {
    id: 3,
    title: "Social media with purpose of capturing authentic life events",
  },
  {
    id: 4,
    title: "Guided self-discovery of your life's chapters",
  },
  {
    id: 5,
    title: "A Creative and Personalized Journaling Tool",
  },
  {
    id: 6,
    title: "Collaborate and invite others to share their perspective on events",
  },
  {
    id: 7,
    title: "Organized single location for storage of memories",
  },
  {
    id: 8,
    title: "Explore historical timelines of your family",
  },
  {
    id: 9,
    title: "Records are secure and private",
  },
];
