import React from "react";

export default function ThankyouPage() {
  return (
    <div className="thanks-wrapper">
      <div className="thanks-card">
        <h1>Thank You!</h1>
        <h2>Congratulations!</h2>
        <p>
          You're now part of our exclusive community. Get ready for the latest
          updates on our upcoming application. Thank you for subscribing!
        </p>
      </div>
    </div>
  );
}
