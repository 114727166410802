
// Function to allow only numeric input in an event
const numberOnly = (event) => {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
};

// Function to allow only letters in an input field
const lettersOnly = (event) => {
  const inputField = event.target;
  const inputValue = inputField.value;
  const lettersValue = inputValue.replace(/[^a-zA-Z]/g, '');
  inputField.value = lettersValue;
}

// Function to convert plan names to abbreviations
const convertPlan = (plan) => {
  const planMapping = {
    Monthly: "Mo",
    Yearly: "Yr",
  };

  return planMapping[plan] || plan;
};

// Function to calculate integer and decimal parts of a number
const calculateIntegerDecimal = (amount) => {
  const integerNumber = Math.floor(amount);
  const decimalNumber = (amount - integerNumber).toFixed(2) * 100;
  return { integerNumber, decimalNumber };
};

// change format for phone numeber
const formatPhoneNumber = (phoneNumber) => {
  const cleaned = phoneNumber.replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return cleaned;
};


const changeFormatPhoneNumber = (phoneNumber) => {
  return phoneNumber.replace(/\D/g, "");
};

export {
  numberOnly,
  lettersOnly,
  convertPlan,
  calculateIntegerDecimal,
  formatPhoneNumber,
  changeFormatPhoneNumber
}