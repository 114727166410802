/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RoutingNavigation } from "../seeds";
import { Link } from "react-scroll";

const Header = () => {
  const [showHeader, setShow] = useState(false);
  const [ActiveSection, setActiveSection] = useState();
  const [scrollPogestion, setScrollPogestion] = useState();

  const nav = useNavigate();

  const handleClickScroll = (id, path) => {
    // Toggle the showHeader state
    setShow(!showHeader);
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
      if (id === "section-2") {
        element.style.paddingTop = "30px";
      }
      if (id === "section-3") {
        element.style.paddingTop = "70px";
      }
      if (id === "section-4") {
        element.style.paddingTop = "70px";
      }
      if (id === "section-5") {
        element.style.paddingTop = "70px";
      }
    }
    nav(path);
  };

  // Toggle the showHeader state when the button is clicked
  const toggleHeader = () => {
    setShow(!showHeader);
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    setScrollPogestion(scrollPosition);
    console.log("Current scroll position:", scrollPosition);
    const sections = RoutingNavigation.map((item) => ({
      id: item.id,
      element: document.getElementById(item.id),
    }));

    for (let i = 0; i < sections.length; i++) {
      const { id, element } = sections[i];

      if (id === "section-6") {
        const sectionTop = element?.offsetTop - 400;
        const sectionBottom = sectionTop + element?.offsetHeight;
        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
          setActiveSection(id);
        }
      } else {
        const sectionTop = element?.offsetTop - 50;
        const sectionBottom = sectionTop + element?.offsetHeight;

        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
          setActiveSection(id);
        }
      }
    }
  };

  useEffect(() => {
    // Listen to the scroll event
    window.addEventListener("scroll", handleScroll);

    // Ensure that we scroll to the correct section when the page loads
    handleScroll();

    if (ActiveSection === "section-1") {
      nav("/");
    }

    if (ActiveSection === "section-2") {
      nav("/why-us");
    }
    if (ActiveSection === "section-3") {
      nav("/features");
    }
    if (ActiveSection === "section-4") {
      nav("/pricing");
    }
    if (ActiveSection === "section-5") {
      nav("/faq");
    }
    if (ActiveSection === "section-6") {
      nav("/contact-us");
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ActiveSection]);

  return (
    <header className="bg-header">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="header-wrapper">
              <div className="logo-blk">
                <a>
                  <img src="assets/images/logo.svg" alt="Logo" />
                </a>
              </div>
              <div className="header-contact-blk">
                <div
                  className={
                    showHeader
                      ? "menu-inner-wrapper show"
                      : "menu-inner-wrapper"
                  }
                >
                  <ul>
                    {RoutingNavigation.map((item) => (
                      <li className="layer-link" key={item.id}>
                        <Link
                          activeClass="active"
                          to={item.id}
                          spy={true}
                          smooth={true}
                          offset={-10}
                          duration={500}
                        >
                          <a
                            className={
                              item.path ===
                              `/${window.location.pathname.split("/")[1]}`
                                ? "page-links active"
                                : "page-links"
                            }
                            onClick={() =>
                              handleClickScroll(item?.id, item?.path)
                            }
                          >
                            {item.name}{" "}
                          </a>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="menu-btn-blk d-lg-none">
                  <button
                    className={showHeader ? "btn-box active" : "btn-box"}
                    onClick={toggleHeader}
                  >
                    <div className="btn_bar btn_width top" />
                    <div className="btn_bar" />
                    <div className="btn_bar btn_width bottom" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
