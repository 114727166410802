/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate } from "react-router-dom";
const HomeSection = () => {
  const nav = useNavigate();

  return (
    <div>
      <section className="hero-section" id="section-1">
        <div className="container">
          <div className="row gy-4 align-items-center">
            <div className="col-lg-6 order-lg-1 order-2">
              <div className="hero-wrapper">
                <div className="heading-blk">
                  <h1 data-aos="fade-right" data-aos-duration="1500">
                    Crafting and preserving your authentic legacy
                  </h1>
                  <div
                    className="app-btn-blk"
                    data-aos="fade-left"
                    data-aos-duration="1500"
                    onClick={() => {
                      nav("/waitlist");

                      window.location.reload();
                    }}
                  >
                    <a className="app-link">
                      <img src="assets/images/appstore.png" alt="App Store" />
                    </a>
                    <a className="app-link">
                      <img
                        src="assets/images/googleplay.png"
                        alt="Google pay"
                      />
                    </a>
                  </div>
                  {/* <div className="trusted-ppl-blk" data-aos="fade-right" data-aos-duration="1500">
                                        <p className="trusted-text">Trusted by people around the world ...</p>
                                    </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 order-lg-2 order-1">
              <div
                className="hero-img-blk"
                data-aos="zoom-in-up"
                data-aos-duration="1500"
              >
                <img src="assets/images/hero-img.png" alt="Image" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="feature-section">
        <div className="container">
          <div className="row justify-content-center align-items-center gy-5">
            <div className="col-xl-2">
              <div className="featured-heading">
                <h2>As Featured in</h2>
              </div>
            </div>
            <div className="col-xl-10">
              <div className="feature-logo-wrapper">
                <div className="feature-logo-list">
                  <ul>
                    <li>
                      <img src="assets/images/fox-wordmark.png" alt="fox wordmark" />
                    </li>
                    <li>
                      <img src="assets/images/the-times-logo.png" alt="the times" />
                    </li>
                    <li>
                      <img src="assets/images/cbs-logo.png" alt="cbs" />
                    </li>
                    <li>
                      <img src="assets/images/nbc-logo.png" alt="nbc" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="vision-section" id="section-1">
        <div className="container">
          <div className="row gy-4 align-items-center">
            <div className="col-lg-6 pe-lg-5">
              <div
                className="vision-img-wrapper"
                data-aos="zoom-in-up"
                data-aos-duration="1500"
              >
                <img src="assets/images/family-vision-2.png" alt="Images" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="vision-blk">
                <h2>
                  Your Legacy: Stitching True Stories into Time's Tapestry:
                </h2>
                <p>
                  At Lumakin, we understand the value of authenticity in a world
                  where realities are constantly shifting. Our mission is to
                  honor and safeguard your personal stories in their purest
                  form, ensuring they remain vivid and unaltered by time's
                  passage. We believe in the power of real-life stories and the
                  relationships that enrich them, interlacing to form a timeless
                  tapestry that illustrates our shared human experience. By
                  doing so, we create a living legacy that interconnects
                  yesterday, today, and tomorrow in an enduring narrative.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="guide-section" id="section-1">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="heading-blk">
                <h2>Document Your Life's Narrative with Lumakin </h2>
              </div>
            </div>
          </div>
          <div className="guide-wrap">
            <div className="row align-items-center grid-position">
              <div className="col-lg-6 pe-lg-5">
                <div className="content-blk">
                  <p>
                    At Lumakin, we provide a unique platform for you to record
                    the authentic tale of your life. Our service is designed to
                    celebrate and preserve your individual narrative and all the
                    experiences that come with it. Whether it's capturing
                    everyday moments, chronicling your family history,
                    expressing your personal viewpoint, or writing your
                    autobiography, Lumakin equips you with the right resources
                    to bring your story to life. 
                  </p>
                  <p>
                    Lumakin combines the features of genealogy websites with the
                    user-friendly experience of social media. It provides guided
                    processes, personalized insights, and self-guided interviews
                    to gather information and organize memories. You can upload
                    digital media files and share your own recollections of
                    events along with allowing others to add to the event
                    albums. 
                  </p>
                </div>
              </div>
              <div className="col-lg-6 ps-lg-5">
                <div
                  className="guide-img-blk"
                  data-aos="zoom-in-up"
                  data-aos-duration="2500"
                >
                  <img src="assets/images/family-1.png" alt="Image" />
                </div>
              </div>
            </div>
            <div className="row flex-row-reverse align-items-center grid-position">
              <div className="col-lg-6 ps-lg-5">
                <div className="content-blk">
                  <p>
                    Lumakin is a centralized space where you can store precious
                    family files, share insights, and connect with loved ones
                    personalized life story and timeline. Lumakin offers a
                    private, secure and interactive environment where we can
                    collaboratively shape our legacies. 
                  </p>
                  <p>
                    The story of your life is worth telling now—don't put off
                    capturing your legacy. With Lumakin, you can start today to
                    ensure your memories are kept for a lifetime and beyond.   
                  </p>
                </div>
              </div>
              <div className="col-lg-6 pe-lg-5">
                <div
                  className="guide-img-blk"
                  data-aos="zoom-in-up"
                  data-aos-duration="2500"
                >
                  <img src="assets/images/family-2.png" alt="Image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomeSection;
