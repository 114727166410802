/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { toast as prompt } from "react-hot-toast";
import CryptoJS from "crypto-js";
import axios from "axios";
import { toast } from "react-toastify";
import {
  changeFormatPhoneNumber,
  formatPhoneNumber,
  numberOnly,
} from "../utils/helpers";
import { FeaturesArray } from "../seeds";
import {
  waitlistInitialValues,
  waitlistValidationSchema,
} from "../validations/schema";
import { useLocation, useNavigate } from "react-router-dom";

const Waitlist = () => {
  const [chipData, setChipData] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [deviceType, setdeviceType] = useState("Android");
  const data = useLocation();
  const getData = data.state;
  console.log(getData);
  const nav = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //Encryption function

  function encrypt(text) {
    const key = process.env.REACT_APP_AUTH_TOKEN;
    const keyBytes = CryptoJS.enc.Hex.parse(key);
    const iv = CryptoJS.lib.WordArray.random(16);
    const encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(text),
      keyBytes,
      { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
    );

    return (
      iv.toString() + ":" + encrypted.ciphertext.toString(CryptoJS.enc.Hex)
    );
  }

  const question11Ref = useRef(null);
  const question22Ref = useRef(null);

  const handlePrompt = async (message) => {
    return new Promise((resolve) => {
      prompt(
        () => (
          <div className="d-flex flex-column gap-3 rounded px-2 py-2">
            <span dangerouslySetInnerHTML={{ __html: message }} />
            <div className="d-flex gap-3 justify-evenly">
              <button
                type="button"
                style={{
                  background: "#30c6cc",
                  borderRadius: "12px",
                  color: "#fff",
                }}
                className="btn rounded-1 w-50 submit-ticket"
                onClick={() => {
                  prompt.dismiss();
                  resolve(true);
                }}
              >
                Yes
              </button>
              <button
                type="button"
                style={{
                  background: "#eee",
                  borderRadius: "12px",
                  color: "black",
                }}
                className="btn rounded-1 w-50 submit-ticket"
                onClick={() => {
                  prompt.dismiss();
                  resolve(false);
                }}
              >
                No
              </button>
            </div>
          </div>
        ),
        { position: "top-center", duration: Infinity }
      );
    });
  };

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: waitlistInitialValues,
    validationSchema: waitlistValidationSchema,
    onSubmit: async (values) => {
      let conditionCheck = 0;

      if (
        values.question1.trim().length === 0 &&
        values.question2.trim().length === 0
      ) {
        conditionCheck += 1;
        const userConfirmed = await handlePrompt(
          "Your input is really important to us to ensure we have a product that will meet your needs. &nbsp Would you like to proceed without adding what is most important to you in crafting, preserving and sharing your life story."
        );

        if (!userConfirmed) {
          question11Ref.current.focus();
          return false;
        }
      }

      if (
        (values.question1.trim().length === 0 ||
          values.question2.trim().length === 0) &&
        conditionCheck === 0
      ) {
        const userConfirmed = await handlePrompt(
          `Thank you for filling out what is most important to you in crafting, preserving and sharing your life story. 
            &nbsp Describe what needs to happen to meet your need to:<b> ${
              values.question1.trim().length !== 0
                ? values.question1.trim()
                : values.question2.trim()
            } </b> will ensure we build something of value specific to you. 
          &nbsp Would you like to proceed without answering what needs to happen?`
        );

        if (!userConfirmed) {
          if (values.question1.trim().length === 0) {
            question11Ref.current.focus();
          } else {
            question22Ref.current.focus();
          }
          return false;
        }
      }

      let data = {
        name: values.firstName + " " + values.lastName,
        phoneNumber: changeFormatPhoneNumber(values.mobileNumber),
        email: values.emailAddress,
        feature: values.selectedFeatures,
        question1: values.question1,
        question2: values.question2,
        device: values.device,
        otherFeatures: values.otherFeatures.map((data) => data.name),
        type: "1",
      };

      try {
        let encryptedData = encrypt(data);

        axios.post("https://lumakin-dev.csdevhub.com/v1/api/email/", data, {
          headers: {
            Authorization: encryptedData,
            'Access-Control-Allow-Origin': '*', 
          },
        });

        // nav("/thank-you");
        setOpenModal(true);
        setTimeout(() => {
          setOpenModal(false);
          nav("/");
          // window.location.reload();
          resetForm();
        }, 3000);
      } catch (error) {
        console.log("error 07>>> ", error);
      }
    },
  });

  const handleChipChange = (e) => {
    setChipData(e.target.value);
  };

  const handleFeatureChange = (featureId, isChecked) => {
    setValues((prevValues) => {
      const { selectedFeatures } = prevValues;
      let updatedFeatures;

      if (isChecked) {
        updatedFeatures = [...selectedFeatures, featureId];
      } else {
        updatedFeatures = selectedFeatures.filter((id) => id !== featureId);
      }

      return { ...prevValues, selectedFeatures: updatedFeatures };
    });
  };

  const handleEnterPress = (event) => {
    if (event.key === "Enter" && event.target === document.activeElement) {
      addChipData();
    }
  };

  const addChipData = () => {
    if (chipData.trim() !== "") {
      setValues((prev) => {
        const updatedValues = { ...prev };
        const newChip = { name: chipData };

        updatedValues.otherFeatures = [...updatedValues.otherFeatures, newChip];

        return updatedValues;
      });
      setChipData("");
      console.log(values.otherFeatures);
    }
  };

  const removeItem = (index) => {
    setValues((prev) => {
      const updatedValues = { ...prev };
      updatedValues.otherFeatures = prev.otherFeatures.filter(
        (item, i) => i !== index
      );

      return updatedValues;
    });
  };
  const handleChangedevice = (event) => {
    const value = event.target.value;
    if (value == "Android") {
      setdeviceType("Android");
    }
    if (value == "IOS") {
      setdeviceType("iOS");
    }
    if (value == "Other") {
      setdeviceType("Other");
    }
  };
  useEffect(() => {
    setFieldValue("device", deviceType);
  }, [deviceType]);
  return (
    <div>
      <div className="waitlist-body">
        <div className="new-header-blk">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div
                  className="newlogo-blk"
                  onClick={() => {
                    window.history.back();
                  }}
                >
                  <a className="backbtn">
                    <span className="backicon">
                      <i className="fa-solid fa-angle-left" />
                    </span>
                    Back
                  </a>
                  <a className="logobtn">
                    <img src="assets/images/logo.svg" alt="Logo" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section class="new-layout spacer-y">
          <div class="container">
            <div class="row gy-4 justify-content-center align-items-center">
              <div class="col-lg-10">
                <div className="common-wrapper">
                  <div className="signup-wrapper">
                    <div class="form-header pt-0">
                      <h2 class="modal-title">
                        Be the first to know about our new app!
                      </h2>
                      <p>
                        Join our waitlist and stay informed about our progress,
                        features, surveys, and beta opportunities.
                      </p>
                    </div>

                    <div className="form-wrapper">
                      <form>
                        <div className="signup-form-blk border-0">
                          <div className="row g-3">
                            <h2 className="modal-heading">
                              Tell us about yourself
                            </h2>
                            <div className="col-sm-6">
                              <div className="signup-fields">
                                <label>First Name</label>
                                <input
                                  value={values?.firstName}
                                  onChange={handleChange}
                                  name="firstName"
                                  onBlur={handleBlur}
                                  type="text"
                                  className="form-control"
                                  id="signupFname"
                                  placeholder="First name"
                                />
                                {errors?.firstName && touched?.firstName && (
                                  <p className="error pt-0 px-2">
                                    {errors.firstName}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="signup-fields">
                                <label>Last Name</label>
                                <input
                                  value={values?.lastName}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  name="lastName"
                                  type="text"
                                  className="form-control"
                                  id="signupLname"
                                  placeholder="Last name"
                                />
                                {errors?.lastName && touched?.lastName && (
                                  <p className="error pt-0 px-2">
                                    {errors.lastName}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="signup-fields">
                                <label>Phone Number</label>
                                <input
                                  value={values?.mobileNumber}
                                  onChange={(e) => {
                                    handleChange(e);
                                    const { name, value } = e.target;
                                    const formattedPhoneNumber =
                                      formatPhoneNumber(value);
                                    setValues((prevValues) => ({
                                      ...prevValues,
                                      [name]: formattedPhoneNumber,
                                    }));
                                  }}
                                  name="mobileNumber"
                                  onBlur={handleBlur}
                                  onKeyPress={numberOnly}
                                  maxLength={11}
                                  type="tel"
                                  className="form-control"
                                  id="signupNumber"
                                  placeholder="Phone number"
                                />
                                {errors?.mobileNumber &&
                                  touched?.mobileNumber && (
                                    <p className="error pt-0 px-2">
                                      {errors.mobileNumber}
                                    </p>
                                  )}
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="signup-fields">
                                <label>Email</label>
                                <input
                                  value={values?.emailAddress}
                                  onChange={handleChange}
                                  name="emailAddress"
                                  onBlur={handleBlur}
                                  type="email"
                                  className="form-control"
                                  id="signupEmail"
                                  placeholder="Email"
                                />
                                {errors?.emailAddress &&
                                  touched?.emailAddress && (
                                    <p className="error pt-0 px-2">
                                      {errors.emailAddress}
                                    </p>
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12 mt-4">
                              <div className="signup-fields">
                                <label>
                                  What is your phone operating System
                                </label>
                              </div>
                              <div className="selected-buttons">
                                <div className="form-radio-select">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="device"
                                    id="inlineRadio1"
                                    value="Android"
                                    onChange={handleChangedevice}
                                    defaultValue="option1"
                                    defaultChecked
                                  />
                                  <label htmlFor="inlineRadio1">
                                    <span className="radio-check" />
                                    <span className="radio-check-heading">
                                      Android
                                    </span>
                                  </label>
                                </div>
                                <div className="form-radio-select">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="device"
                                    value="IOS"
                                    onChange={handleChangedevice}
                                    id="inlineRadio2"
                                    defaultValue="option2"
                                  />
                                  <label htmlFor="inlineRadio2">
                                    <span className="radio-check" />
                                    <span className="radio-check-heading">
                                      iOS
                                    </span>
                                  </label>
                                </div>
                                <div className="form-radio-select">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="device"
                                    value="Other"
                                    onChange={handleChangedevice}
                                    id="inlineRadio3"
                                    defaultValue="option3"
                                  />
                                  <label htmlFor="inlineRadio3">
                                    <span className="radio-check" />
                                    <span className="radio-check-heading">
                                      Other
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-12 mt-3">
                              <h2 className="modal-heading">
                                When crafting, preserving, and sharing a life
                                story...
                              </h2>
                              <div className="question-blk">
                                <h2>
                                  What feature is most important to capture your
                                  life story?
                                </h2>
                                <div className="question-fields p-relative">
                                  <input
                                    value={values?.question1}
                                    onChange={handleChange}
                                    type="text"
                                    ref={question11Ref}
                                    name="question1"
                                    onBlur={handleBlur}
                                    className="form-control"
                                    placeholder=" eg. Simple to
                                    use"
                                  />
                                </div>
                              </div>
                              <div className="question-blk">
                                <h2>
                                  When this above feature is implemented, I’m
                                  able to do the following…
                                </h2>
                                <div className="question-fields p-relative">
                                  <input
                                    value={values?.question2}
                                    onChange={handleChange}
                                    type="text"
                                    name="question2"
                                    className="form-control"
                                    ref={question22Ref}
                                    onBlur={handleBlur}
                                    placeholder="eg. I
                                    can record my journal entries using
                                    video or audioAdd Answer"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-12">
                              <div className="select-feature-blk">
                                <h2 className="modal-heading">
                                  What existing feature(s) are you most excited
                                  about?
                                </h2>

                                <div className="featureslist">
                                  {FeaturesArray.map((item) => (
                                    <div className="features-list-item">
                                      <input
                                        type="checkbox"
                                        id={`feature-${item.id}`}
                                        onChange={(e) =>
                                          handleFeatureChange(
                                            item.title,
                                            e.target.checked
                                          )
                                        }
                                        checked={
                                          Array.isArray(
                                            values?.selectedFeatures
                                          ) &&
                                          values.selectedFeatures?.includes(
                                            item?.title
                                          )
                                        }
                                      />
                                      <label htmlFor={`feature-${item.id}`}>
                                        <span className="feature-check" />
                                        <span className="feature-heading">
                                          {item.title}
                                        </span>
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <h2 className="modal-heading mt-1">
                                Share any additional features needed to meet
                                your specific intended use...
                              </h2>
                            </div>
                            <div className="col-lg-12">
                              <div className="append-block">
                                <ul className="appendlist-wrapper">
                                  {Array.isArray(values?.otherFeatures) &&
                                    values?.otherFeatures?.map(
                                      (item, index) => (
                                        <li className="appendlist">
                                          <span className="addedfeatuire">
                                            {item?.name}
                                          </span>
                                          <button
                                            type="button"
                                            className="removefeature"
                                          >
                                            <i
                                              onClick={() => removeItem(index)}
                                              className="fa-solid fa-xmark"
                                            />
                                          </button>
                                        </li>
                                      )
                                    )}
                                </ul>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="signup-fields p-relative">
                                <input
                                  value={chipData}
                                  type="text"
                                  className="form-control"
                                  id="others"
                                  placeholder="List additional features needed to capture your life story"
                                  onChange={handleChipChange}
                                  maxLength={90}
                                  onBlur={handleBlur}
                                  onKeyDown={handleEnterPress}
                                />
                                <button
                                  className="addfeatures"
                                  type="button"
                                  onClick={addChipData}
                                >
                                  +Add
                                </button>
                              </div>
                              {errors?.otherFeatures &&
                                touched?.otherFeatures && (
                                  <p className="error pt-0 px-2">
                                    {errors.otherFeatures}
                                  </p>
                                )}
                              <span className="text-title">
                                Max length(90 words)
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="signup-form-btn">
                          <button
                            onClick={handleSubmit}
                            type="button"
                            className="signup-submit-btn"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className={`${openModal ? "custom-modal" : "d-none"}`}>
        <div className={`Congratulation-wrapper ${openModal ? "active" : ""}`}>
          <div className="congrats-img">
            <img src="assets/images/fireworks.png" alt="Congratulations" />
          </div>
          <h2>Congratulations!</h2>
          <p>
            You're now part of our exclusive community. Get ready for the latest
            updates on our upcoming application. Thank you for subscribing!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Waitlist;
