import { useFormik } from "formik";
import React from "react";
import { initialValues, validationSchema } from "../validations/schema";
import axios from "axios";
import {
  changeFormatPhoneNumber,
  formatPhoneNumber,
  lettersOnly,
  numberOnly,
} from "../utils/helpers";
import { toast } from "react-toastify";
import CryptoJS from "crypto-js";

function FormSection() {
  //Encryption function

  function encrypt(text) {
    const key = process.env.REACT_APP_AUTH_TOKEN;
    const keyBytes = CryptoJS.enc.Hex.parse(key);
    const iv = CryptoJS.lib.WordArray.random(16);
    const encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(text),
      keyBytes,
      { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
    );

    return (
      iv.toString() + ":" + encrypted.ciphertext.toString(CryptoJS.enc.Hex)
    );
  }

  // ---------
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let data = {
        name: values.firstname + values.lastname,
        phoneNumber: changeFormatPhoneNumber(values.phone),
        subject: "",
        description: values.message,
        type: "2",
      };
      try {
        let encryptedData = encrypt(data);
        axios.post("https://lumakin-dev.csdevhub.com/v1/api/email/", data, {
          headers: {
            Authorization: encryptedData,
            'Access-Control-Allow-Origin': '*', 
          },
        });
        resetForm();
        toast.success("Email sent successfully");
      } catch (error) {
        console.log("error", error);
      }
    },
  });
  return (
    <div className="footer-contact">
      <div
        className="contact-form"
        data-aos="fade-left"
        data-aos-duration="1500"
      >
        <h2>Contact</h2>
        <form>
          <div className="row g-3">
            <div className="col-sm-6">
              <input
                type="text"
                className="form-control"
                placeholder="First Name"
                aria-label="First Name"
                name="firstname"
                value={values.firstname}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={lettersOnly}
              />
              {errors.firstname && touched.firstname && (
                <p className="error">{errors.firstname}</p>
              )}
            </div>
            <div className="col-sm-6">
              <input
                type="text"
                className="form-control"
                placeholder="Last Name"
                aria-label="Last Name"
                name="lastname"
                value={values.lastname}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={lettersOnly}
              />
              {errors.lastname && touched.lastname && (
                <p className="error">{errors.lastname}</p>
              )}
            </div>
            <div className="col-sm-6">
              <input
                type="text"
                className="form-control"
                placeholder="Phone Number"
                aria-label="Phone Number"
                name="phone"
                value={values.phone}
                onChange={(e) => {
                  handleChange(e);
                  const { name, value } = e.target;
                  const formattedPhoneNumber = formatPhoneNumber(value);
                  setValues((prevValues) => ({
                    ...prevValues,
                    [name]: formattedPhoneNumber,
                  }));
                }}
                onBlur={handleBlur}
                onKeyPress={numberOnly}
                maxLength={11}
              />
              {errors.phone && touched.phone && (
                <p className="error">{errors.phone}</p>
              )}
            </div>
            {/* <div className="col-sm-6">
              <input
                type="text"
                className="form-control"
                placeholder="Subject"
                aria-label="Subject"
                name="subject"
                value={values.subject}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.subject && touched.subject && (
                <p className="error">{errors.subject}</p>
              )}
            </div> */}
            <div className="col-sm-12">
              <textarea
                cols={30}
                className="form-control"
                placeholder="Tell us how we can help"
                rows={5}
                defaultValue={""}
                name="message"
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.message && touched.message && (
                <p className="error">{errors.message}</p>
              )}
            </div>
            <div className="col-sm-12">
              <div className="send-btn-blk">
                <button className="send-message-btn" onClick={handleSubmit}>
                  send message
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default FormSection;
