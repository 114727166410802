import React, { useEffect, useState } from "react";
import Header from "./header";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import CryptoJS from "crypto-js";
import {
  rootstechInitialValues,
  rootstechvalidationSchema,
} from "../validations/schema";
import {
  changeFormatPhoneNumber,
  formatPhoneNumber,
  numberOnly,
} from "../utils/helpers";
import axios from "axios";

export default function RootsTechPage() {
  const [deviceType, setdeviceType] = useState("Android");
  function encrypt(text) {
    const key = process.env.REACT_APP_AUTH_TOKEN;
    const keyBytes = CryptoJS.enc.Hex.parse(key);
    const iv = CryptoJS.lib.WordArray.random(16);
    const encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(text),
      keyBytes,
      { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
    );

    return (
      iv.toString() + ":" + encrypted.ciphertext.toString(CryptoJS.enc.Hex)
    );
  }
  const nav = useNavigate();
  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: rootstechInitialValues,
    validationSchema: rootstechvalidationSchema,
    onSubmit: async () => {
      let data = {
        name: values.firstName + " " + values.lastName,
        phoneNumber: changeFormatPhoneNumber(values.mobileNumber),
        email: values.emailAddress,
        device: values.device,
        type: "1",
      };
      try {
        let encryptedData = encrypt(data);

        axios.post("https://lumakin-dev.csdevhub.com/v1/api/email/", data, {
          headers: {
            Authorization: encryptedData,
            'Access-Control-Allow-Origin': '*', 
          },
        });

        nav("/thank-you");

        setTimeout(() => {
          nav("/");
          // window.location.reload();
          resetForm();
        }, 3000);
      } catch (error) {
        console.log("error 07>>> ", error);
      }
    },
  });
  const handleChangedevice = (event) => {
    const value = event.target.value;
    if (value == "Android") {
      setdeviceType("Android");
    }
    if (value == "IOS") {
      setdeviceType("iOS");
    }
    if (value == "Other") {
      setdeviceType("Other");
    }
  };
  useEffect(() => {
    setFieldValue("device", deviceType);
  }, [deviceType]);
  return (
    <div className="create-bg">
      <Header />
        <section className="create-section">      
          <div className="container">
              <div className="row justify-content-center g-5">
                  <div className="col-lg-7">
                      <div className="create-content-blk">
                          <h1>Create, Share, and Preserve Your Unique Life Story with Lumakin!</h1>     
                          <p className="create-subheading">At Lumakin, we provide a unique platform for you to record the authentic tale of your life.</p>             
                      </div>                    
                  </div>                                       
              </div>            
          </div>
      </section>      
      <section className="video-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-11">
              <div class="video-wrapper">
                  <video className="video" poster="assets/images/poster.png" playsInline controls autoPlay muted loop>
                    <source
                      src="assets/images/lumakin-intro.mp4"
                      type="video/mp4"
                    />
                  </video>
                  {/* <button class="play-btn"><i class="fa-solid fa-play"></i></button> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="create-content-section">
        <div className="container">
          <div className="row justify-content-center">            
            <div className="col-lg-12 col-xl-11">
              <div className="create-content-blk">
                <div className="create-para-blk">                  
                  <p>
                    The new Lumakin app is designed to celebrate and preserve
                    your individual narrative and all the experiences that come
                    with it. Whether it’s capturing everyday moments,
                    chronicling your family history, expressing your personal
                    viewpoint, or writing your autobiography, Lumakin equips you
                    with the right resources to bring your story to life.
                  </p>
                </div>
                <ul>
                  <li>Create your own autobiographry</li>
                  <li>Record and manage your personal journal</li>
                  <li>
                    Store photos, videos, and audio that capture the essence of
                    your life
                  </li>
                  <li>
                    Share your life with those who matter most, both now and in
                    the future
                  </li>
                </ul>
                
              </div>
            </div>            
          </div>
          <div className="row justify-content-center">
          <div className="col-12">
              <div className="create-form-heading">
                <h2>
                  Lumakin is the perfect blend of genealogy and social media.
                </h2>
                <h3 className="text-center">
                  Join the waitlist and get a free 7-day trial at launch!
                </h3>
              </div>
          </div>
          <div className="col-lg-12 col-xl-6">                
                <div className="row gy-4 mt-4">
                  <div className="col-sm-6">
                    <div className="signup-fields">
                      <label>First Name</label>
                      <input
                        name="firstName"
                        value={values?.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        className="form-control"
                        id="signupFname"
                        placeholder="First name"
                      />
                      {errors?.firstName && touched?.firstName && (
                        <p className="error pt-0 px-2">{errors.firstName}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="signup-fields">
                      <label>Last Name</label>
                      <input
                        name="lastName"
                        value={values?.lastName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        id="signupLname"
                        placeholder="Last name"
                      />
                      {errors?.lastName && touched?.lastName && (
                        <p className="error pt-0 px-2">{errors.lastName}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="signup-fields">
                      <label>Phone Number</label>
                      <input
                        value={values?.mobileNumber}
                        onChange={(e) => {
                          handleChange(e);
                          const { name, value } = e.target;
                          const formattedPhoneNumber = formatPhoneNumber(value);
                          setValues((prevValues) => ({
                            ...prevValues,
                            [name]: formattedPhoneNumber,
                          }));
                        }}
                        name="mobileNumber"
                        onBlur={handleBlur}
                        onKeyPress={numberOnly}
                        maxLength={11}
                        type="tel"
                        className="form-control"
                        id="signupNumber"
                        placeholder="Phone number"
                      />
                      {errors?.mobileNumber && touched?.mobileNumber && (
                        <p className="error pt-0 px-2">{errors.mobileNumber}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="signup-fields">
                      <label>Email</label>
                      <input
                        value={values?.emailAddress}
                        onChange={handleChange}
                        name="emailAddress"
                        onBlur={handleBlur}
                        type="email"
                        className="form-control"
                        id="signupEmail"
                        placeholder="Email"
                      />
                      {errors?.emailAddress && touched?.emailAddress && (
                        <p className="error pt-0 px-2">{errors.emailAddress}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <div className="signup-fields">
                      <label>What is your phone operating System</label>
                    </div>
                    <div className="selected-buttons">
                      <div className="form-radio-select">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="device"
                          id="inlineRadio1"
                          value="Android"
                          onChange={handleChangedevice}
                          defaultValue="option1"
                          defaultChecked
                        />
                        <label htmlFor="inlineRadio1">
                          <span className="radio-check" />
                          <span className="radio-check-heading">Android</span>
                        </label>
                      </div>
                      <div className="form-radio-select">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="device"
                          value="IOS"
                          onChange={handleChangedevice}
                          id="inlineRadio2"
                          defaultValue="option2"
                        />
                        <label htmlFor="inlineRadio2">
                          <span className="radio-check" />
                          <span className="radio-check-heading">iOS</span>
                        </label>
                      </div>
                      <div className="form-radio-select">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="device"
                          value="Other"
                          onChange={handleChangedevice}
                          id="inlineRadio3"
                          defaultValue="option3"
                        />
                        <label htmlFor="inlineRadio3">
                          <span className="radio-check" />
                          <span className="radio-check-heading">Other</span>
                        </label>
                      </div>
                    </div>
                    <div className="btn-blk mt-4">
                      <button
                        className="submit-btn-layout"
                        type="button"
                        onClick={() => handleSubmit()}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              
              
            </div>
          </div>
        </div>
      </section>
    
      
      
    </div>
  );
}
