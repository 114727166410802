import * as Yup from "yup";

// You can adjust the regular expression to match your phone number format
const phoneRegExp = /^[0-9]{10}$/;

const phoneNumberSchema = Yup.string()
  .matches(phoneRegExp, "Phone number must be 10 digits")
  .required("Phone number is required");

const initialValues = {
  firstname: "",
  lastname: "",
  subject: "",
  message: "",
  phone: "",
  question1: "",
  question2: "",
};

const waitlistInitialValues = {
  firstName: "",
  lastName: "",
  mobileNumber: "",
  emailAddress: "",
  question1: "",
  question2: "",
  device: "",
  selectedFeatures: [],
  otherFeatures: [],
};
const rootstechInitialValues = {
  firstName: "",
  lastName: "",
  mobileNumber: "",
  emailAddress: "",
  device: "",
};
const rootstechvalidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "First Name must be at least 2 characters*")
    .required("First Name is required*"),
  lastName: Yup.string()
    .min(2, "Last Name must be at least 2 characters*")
    .required("Last Name is required*"),
  emailAddress: Yup.string()
    .email("Invalid email address")
    .required("Email address is required"),
  device: Yup.string(),
  mobileNumber: Yup.string()
    .min(10, "Phone number must be 10 digits")
    .required("Phone number is required"), // Include the phone number schema
});

const validationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(2, "First Name must be at least 2 characters*")
    .required("First Name is required*"),
  lastname: Yup.string()
    .min(2, "Last Name must be at least 2 characters*")
    .required("Last Name is required*"),
  // subject: Yup.string()
  //   .min(10, "Subject must be at least 10 characters*")
  //   .required("Subject is required*"),
  message: Yup.string()
    .min(10, "Message must be at least 10 characters*")
    .required("Message is required*"),
  device: Yup.string(),
  phone: Yup.string()
    .min(10, "Phone number must be 10 digits")
    .required("Phone number is required"), // Include the phone number schema
});

const waitlistValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  mobileNumber: Yup.string()
    .min(10, "Mobile number must be 10 digits")
    .required("Mobile number is required"),
  emailAddress: Yup.string()
    .email("Invalid email address")
    .required("Email address is required"),
  // selectedFeatures: Yup.array()
  //   .min(1, "Please select at least one feature")
  //   .of(Yup.string()),
});

export {
  initialValues,
  waitlistInitialValues,
  validationSchema,
  waitlistValidationSchema,
  rootstechvalidationSchema,
  rootstechInitialValues,
};
