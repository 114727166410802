import React from "react";
import HomeSection from "../components/homeSection";
import AboutUsSection from "../components/aboutUsSection";
import FeaturesSection from "../components/featuresSection";
import PricingSection from "../components/pricingSection";
import FaqSection from "../components/faqSection";
import BlogSection from "../components/blogSection";

const MainScreen = () => {
  return (
    <div>
      <HomeSection />
      <AboutUsSection />
      <FeaturesSection />
      <PricingSection />
      <FaqSection />
      <BlogSection />
    </div>
  );
};

export default MainScreen;
