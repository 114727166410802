import React from "react";

export default function PrivacyPolicyPage() {
  return (
    <div className="text-wrapper">
      <div className="text">
        <h1>Privacy Policy</h1>
        <h2>Introduction</h2>
        <p>
          This Privacy Policy describes how Lumakin collects, uses, and
          discloses information, including personally identifiable information
          ("personal information"), in connection with your use of our mobile
          application, Lumakin Application.
        </p>
        <h2>Information We Collect</h2>
        <p>
          We collect the following information from and about you when you use
          the App:
        </p>
        <p>
          <strong>Personal Information:</strong> This may include your name,
          email address, phone number, username, and profile picture (if
          applicable). We may collect this information when you create an
          account, contact us, or use certain features of the App.
        </p>
        <p>
          <strong>Usage Data:</strong> This includes information about your
          activity on the App, such as the features you use, the time and
          duration of your use, and the content you access. We may collect this
          information automatically using cookies and other tracking
          technologies.
        </p>
        <p>
          <strong>Device Information:</strong> This includes information about
          the device you use to access the App, such as the device type,
          operating system, unique device identifier, IP address, and browser
          information. We may collect this information automatically.
        </p>
        <h2>How We Use Your Information</h2>
        <h3>We use the information we collect for the following purposes:</h3>
        <ul>
          <li>To operate and improve the App</li>
          <li>To personalize your experience with the App</li>
          <li>To provide you with customer support</li>
          <li>To send you marketing materials (with your consent)</li>
          <li>To comply with legal and regulatory obligations</li>
          <li>Sharing Your Information</li>
        </ul>
        <h3>We may share your information with the following third parties:</h3>
        <p>
          Service providers who help us operate the App, such as cloud storage
          providers and data analytics providers. Law enforcement or other
          government agencies, if required by law.
        </p>
        <p>
          We will not sell or share your personal information with any third
          parties for marketing purposes without your consent.
        </p>
        <h2>Your Choices</h2>
        <h3>You have the following choices regarding your information:</h3>
        <ul>
          <li>
            You can access and update your personal information in your account
            settings.
          </li>
          <li>
            You can opt out of receiving marketing communications from us.
          </li>
          <li>You can delete your account at any time.</li>
        </ul>
        <h2>Security</h2>
        <p>
          We take reasonable steps to protect your information from unauthorized
          access, disclosure, alteration, or destruction. However, no internet
          or electronic storage system is 100% secure.
        </p>
        <h2>Changes to this Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by posting the new Privacy Policy on the App.
        </p>
        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at our official website.
          <a href="https://www.lumakin.com" target="_blank">
            https://www.lumakin.com
          </a>
          .
        </p>
      </div>
    </div>
  );
}
