import React from 'react'

const FeaturesSection = () => {
    return (
           <section className="features-section" id='section-3'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="features-blk">
                                <h2>Discover The Features</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-xl-10">
                            <div className="feature-card">
                                <div className="feature-card-img" data-aos="zoom-in-up" data-aos-duration="2500">
                                    <img src="assets/images/feature-img-1.png" alt="Images" />
                                </div>
                                <div className="feature-card-inner">
                                    <h3> Platform for your personal story</h3>
                                    <p>At Lumakin, crafting the story of your life is more than a pastime; it's a way to etch your legacy into the fabric of time. With a focus on preserving your individual experiences, our platform offers a special home for the personal stories that define you. Create a living document of who you are, beyond just names and dates, ensuring your descendants know the person behind the family tree.</p>
                                </div>
                            </div>
                            <div className="feature-card">
                                <div className="feature-card-img" data-aos="zoom-in-up" data-aos-duration="2500">
                                    <img src="assets/images/feature-img-2.png" alt="Images" />
                                </div>
                                <div className="feature-card-inner">
                                    <h3>Digitize your life</h3>
                                    <p>Allow us to capture the essence of your being—your voice, laughter, image and wisdom—in a format that stands the test of time. From the subtle inflections in your storytelling to the joyous laughter in family videos, we keep the sensory richness of your memories intact for you and your loved ones to experience on demand.</p>
                                </div>
                            </div>
                            
                            <div className="feature-card">
                                <div className="feature-card-img" data-aos="zoom-in-up" data-aos-duration="2500">
                                    <img src="assets/images/feature-img-5.png" alt="Images" />
                                </div>
                                <div className="feature-card-inner">
                                    <h3>Social media with purpose of capturing authentic life events</h3>
                                    <p>Navigate Lumakin's interface with the familiarity of social media, designed for ease of use and enriched by meaningful interaction. Document the landmarks and daily nuances of your life's journey using events, enjoying the camaraderie and connection of a social platform while building a living record of your personal history of events, interviews or journal entries.</p>
                                </div>
                            </div>
                            <div className="feature-card">
                                <div className="feature-card-img" data-aos="zoom-in-up" data-aos-duration="2500">
                                    <img src="assets/images/feature-img-7.png" alt="Images" />
                                </div>
                                <div className="feature-card-inner">
                                    <h3>Guided self-discovery of your life's chapters</h3>
                                    <p>Lumakin is a platform that helps you create your own autobiography with ease and insight. Using structured prompts on-demand or per a schedule, you can use interviews to capture your personal story, capturing the full spectrum of your life’s experiences, including repeating questions to track fun facts over time. You can choose to conduct interviews of others, or request their input on events to fill in the gaps of your own recollection to build a comprehensive narrative of life experiences.</p>
                                </div>
                            </div>

                            <div className="feature-card">
                                <div className="feature-card-img" data-aos="zoom-in-up" data-aos-duration="2500">
                                    <img src="assets/images/feature-img-12.png" alt="Images" />
                                </div>
                                <div className="feature-card-inner">
                                    <h3>A Creative and Personalized Journaling Tool</h3>
                                    <p>Lumakin offers a unique service for journaling enthusiasts. It allows users to use freeform or guided templates to create beautiful journal pages. Users can also add your own photos, text, and voice recordings to make the journal entries more expressive and memorable. Lumakin is a great tool for anyone who wants to capture their thoughts, feelings, and experiences in a creative and fun way.</p>
                                </div>
                            </div>
                            <div className="feature-card">
                                <div className="feature-card-img" data-aos="zoom-in-up" data-aos-duration="2500">
                                    <img src="assets/images/feature-img-11.png" alt="Images" />
                                </div>
                                <div className="feature-card-inner">
                                    <h3>Collaborate and invite others to share their perspective on events</h3>
                                    <p>Create a living legacy with those you love on Lumakin. The platform encourages collaborative storytelling, inviting your circle to add to the narrative, enriching your story with their own. As each person contributes, your collective memory grows, forming a rich legacy woven directly into the fabric of your timeline. 
                                    </p>
                                </div>
                            </div>
                            <div className="feature-card">
                                <div className="feature-card-img" data-aos="zoom-in-up" data-aos-duration="2500">
                                    <img src="assets/images/feature-img-9.png" alt="Images" />
                                </div>
                                <div className="feature-card-inner">
                                    <h3> Organized single location for storage of memories </h3>
                                    <p> Our platform provides a sanctuary for your digital memories, offering a robust system to upload, categorize, and manage a rich array of media files. Build and share your digital albums that tell the story of your life through event posts, interviews, or journals that allow for embedded text, documents, pictures, videos, and audio—each piece a brushstroke in the masterpiece of your timeline.</p>
                                </div>
                            </div>
                            <div className="feature-card">
                                <div className="feature-card-img" data-aos="zoom-in-up" data-aos-duration="2500">
                                    <img src="assets/images/feature-img-4.png" alt="Images" />
                                </div>
                                <div className="feature-card-inner">
                                    <h3>Explore historical timelines of your family</h3>
                                    <p>Lumakin's genealogy features help to trace your roots but also to weave your family's historical data into a continuous narrative. Watch as your shared history unfolds over time, capturing the evolution and growth of your lineage on a dynamic family timeline. </p>
                                </div>
                            </div>
                            <div className="feature-card">
                                <div className="feature-card-img" data-aos="zoom-in-up" data-aos-duration="2500">
                                    <img src="assets/images/feature-img-10.png" alt="Images" />
                                </div>
                                <div className="feature-card-inner">
                                    <h3>Records are secure and private</h3>
                                    <p>We understand the importance of privacy in preserving your personal history. Lumakin offers a secure and private environment for you to share your life's stories on your terms. Control who sees what, ensuring that your personal recollections remain as intimate or as public as you wish, within a digital space that prioritizes the integrity of your narrative. Even allow selected memories to be destroyed or designate the entire account transfer of ownership upon your death.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
       
    )
}

export default FeaturesSection;
