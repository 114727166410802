import CryptoJS from "crypto-js";
import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import { toast as prompt } from "react-hot-toast";
import { toast } from "react-toastify";
import { FeaturesArray } from "../seeds";
import { numberOnly } from "../utils/helpers";
import axios from "axios";
import {
  waitlistInitialValues,
  waitlistValidationSchema,
} from "../validations/schema";

const WaitLiastModal = () => {
  const [openModal, setOpenModal] = useState(false);
  const [showSignupForm, setShowSignupForm] = useState(true);
  const [chipData, setChipData] = useState("");
  const closeButtonRef = useRef(null);

  //Encryption function

  function encrypt(text) {
    const key = process.env.REACT_APP_AUTH_TOKEN;
    const keyBytes = CryptoJS.enc.Hex.parse(key);
    const iv = CryptoJS.lib.WordArray.random(16);
    const encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(text),
      keyBytes,
      { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
    );

    return (
      iv.toString() + ":" + encrypted.ciphertext.toString(CryptoJS.enc.Hex)
    );
  }

  const question11Ref = useRef(null);
  const question22Ref = useRef(null);

  const handlePrompt = async (message) => {
    return new Promise((resolve) => {
      prompt(
        () => (
          <div className="d-flex flex-column gap-3 rounded px-2 py-2 ">
            <span dangerouslySetInnerHTML={{ __html: message }} />
            <div className="d-flex gap-3 justify-evenly">
              <button
                type="button"
                style={{
                  background: "#30c6cc",
                  borderRadius: "12px",
                  color: "#fff",
                }}
                className="btn rounded-1 w-50 submit-ticket"
                onClick={() => {
                  prompt.dismiss();
                  resolve(true);
                }}
              >
                Yes
              </button>
              <button
                type="button"
                style={{
                  background: "#eee",
                  borderRadius: "12px",
                  color: "black",
                }}
                className="btn rounded-1 w-50 submit-ticket"
                onClick={() => {
                  prompt.dismiss();
                  resolve(false);
                }}
              >
                No
              </button>
            </div>
          </div>
        ),
        { position: "top-center", duration: Infinity }
      );
    });
  };

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: waitlistInitialValues,
    validationSchema: waitlistValidationSchema,
    onSubmit: async (values) => {
      let conditionCheck = 0;

      if (
        values.question1.trim().length === 0 &&
        values.question2.trim().length === 0
      ) {
        conditionCheck += 1;
        const userConfirmed = await handlePrompt(
          "Thank you for filling out what is most important to you in crafting, preserving and sharing your life story. Describe what needs to happen to meet your need to: test feature will ensure we build something of value specific to you. Would you like to proceed without answering what needs to happen?"
        );

        if (!userConfirmed) {
          question11Ref.current.focus();
          return false;
        }
      }

      if (
        (values.question1.trim().length === 0 ||
          values.question2.trim().length === 0) &&
        conditionCheck === 0
      ) {
        const userConfirmed = await handlePrompt(
          `Thank you for filling out what is most important to you in crafting, preserving and sharing your life story. Describe what needs to happen to meet your need to: <b> ${
            values.question1.trim().length !== 0
              ? values.question1.trim()
              : values.question2.trim()
          } </b> will ensure we build something of value specific to you. Would you like to proceed without answering what needs to happen?`
        );

        if (!userConfirmed) {
          if (values.question1.trim().length === 0) {
            question11Ref.current.focus();
          } else {
            question22Ref.current.focus();
          }
          return false;
        }
      }

      let data = {
        name: values.firstName + " " + values.lastName,
        phoneNumber: values.mobileNumber,
        email: values.emailAddress,
        feature: values.selectedFeatures,
        question1: values.question1,
        question2: values.question2,
        otherFeatures: values.otherFeatures.map((data) => data.name),
        type: "1",
      };
      try {
        let encryptedData = encrypt(data);
        axios.post("https://lumakin-dev.csdevhub.com/v1/api/email/", data, {
          headers: {
            Authorization: encryptedData,
            'Access-Control-Allow-Origin': '*', 
          },
        });
        setOpenModal(true);
        toast.success("Email sent successfully");
        resetForm();
        setShowSignupForm(false);
      } catch (error) {
        console.log("error 07>>> ", error);
      }
    },
  });

  React.useEffect(() => {
    if (openModal === true) {
      const closeModalTimeout = setTimeout(() => {
        if (closeButtonRef.current) {
          closeButtonRef.current.click();
        }

        setShowSignupForm(true);
        resetForm();
      }, 3000);

      return () => clearTimeout(closeModalTimeout);
    }
  }, [openModal, resetForm, setShowSignupForm]);

  const handleChipChange = (e) => {
    setChipData(e.target.value);
  };

  const handleFeatureChange = (featureId, isChecked) => {
    setValues((prevValues) => {
      const { selectedFeatures } = prevValues;
      let updatedFeatures;

      if (isChecked) {
        updatedFeatures = [...selectedFeatures, featureId];
      } else {
        updatedFeatures = selectedFeatures.filter((id) => id !== featureId);
      }

      return { ...prevValues, selectedFeatures: updatedFeatures };
    });
  };

  const handleEnterPress = (event) => {
    if (event.key === "Enter" && event.target === document.activeElement) {
      addChipData();
    }
  };

  const addChipData = () => {
    if (chipData.trim() !== "") {
      setValues((prev) => {
        const updatedValues = { ...prev };
        const newChip = { name: chipData };

        updatedValues.otherFeatures = [...updatedValues.otherFeatures, newChip];

        return updatedValues;
      });
      setChipData("");
      console.log(values.otherFeatures);
    }
  };

  const removeItem = (index) => {
    setValues((prev) => {
      const updatedValues = { ...prev };
      updatedValues.otherFeatures = prev.otherFeatures.filter(
        (item, i) => i !== index
      );

      return updatedValues;
    });
  };

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return cleaned;
  };

  const handlChange = (e) => {
    const { name, value } = e.target;

    if (name === "mobileNumber") {
      const formattedPhoneNumber = formatPhoneNumber(value);
      setValues((prevValues) => ({
        ...prevValues,
        [name]: formattedPhoneNumber,
      }));
    } else {
      setValues((prevValues) => ({ ...prevValues, [name]: value }));
    }
  };

  return (
    <div
      className={`modal fade common-modal p-0`}
      id="formModal"
      tabIndex={-1}
      aria-labelledby="formModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body p-0">
            <div className="common-wrapper">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setOpenModal(false);
                  setShowSignupForm(true);
                  resetForm();
                }}
                ref={closeButtonRef}
              />
              {openModal ? (
                <div className="Congratulation-wrapper">
                  <div className="congrats-img">
                    <img
                      src="assets/images/fireworks.png"
                      alt="Congratulations"
                    />
                  </div>
                  <h2>Congratulations!</h2>
                  <p>
                    You're now part of our exclusive community. Get ready for
                    the latest updates on our upcoming application. Thank you
                    for subscribing!
                  </p>
                </div>
              ) : (
                showSignupForm && (
                  <div className="signup-wrapper">
                    <div className="form-header">
                      <h2 className="modal-title">
                        Be the first to know about our new app!
                      </h2>
                      <p>
                        Join our waitlist and stay informed about our progress,
                        features, surveys, and beta opportunities.
                      </p>
                    </div>

                    <div className="form-wrapper">
                      <form>
                        <div className="signup-form-blk">
                          <div className="row g-3">
                            <h2 className="modal-heading">
                              Tell us about yourself
                            </h2>
                            <div className="col-sm-6">
                              <div className="signup-fields">
                                <label>First Name</label>
                                <input
                                  value={values?.firstName}
                                  onChange={handleChange}
                                  name="firstName"
                                  onBlur={handleBlur}
                                  type="text"
                                  className="form-control"
                                  id="signupFname"
                                  placeholder="First name"
                                />
                                {errors?.firstName && touched?.firstName && (
                                  <p className="error pt-0 px-2">
                                    {errors.firstName}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="signup-fields">
                                <label>Last Name</label>
                                <input
                                  value={values?.lastName}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  name="lastName"
                                  type="text"
                                  className="form-control"
                                  id="signupLname"
                                  placeholder="Last name"
                                />
                                {errors?.lastName && touched?.lastName && (
                                  <p className="error pt-0 px-2">
                                    {errors.lastName}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="signup-fields">
                                <label>Phone Number</label>
                                <input
                                  value={values?.mobileNumber}
                                  onChange={handlChange}
                                  name="mobileNumber"
                                  onBlur={handleBlur}
                                  onKeyPress={numberOnly}
                                  maxLength={11}
                                  type="tel"
                                  className="form-control"
                                  id="signupNumber"
                                  placeholder="Phone number"
                                />
                                {errors?.mobileNumber &&
                                  touched?.mobileNumber && (
                                    <p className="error pt-0 px-2">
                                      {errors.mobileNumber}
                                    </p>
                                  )}
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="signup-fields">
                                <label>Email</label>
                                <input
                                  value={values?.emailAddress}
                                  onChange={handleChange}
                                  name="emailAddress"
                                  onBlur={handleBlur}
                                  type="email"
                                  className="form-control"
                                  id="signupEmail"
                                  placeholder="Email"
                                />
                                {errors?.emailAddress &&
                                  touched?.emailAddress && (
                                    <p className="error pt-0 px-2">
                                      {errors.emailAddress}
                                    </p>
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 mt-3">
                              <h2 className="modal-heading">
                                When crafting, preserving, and sharing a life
                                story...
                              </h2>
                              <div className="question-blk">
                                <h2>
                                  What feature is most important to capture your
                                  life story?
                                </h2>
                                <div className="question-fields p-relative">
                                  <input
                                    value={values?.question1}
                                    onChange={handleChange}
                                    type="text"
                                    ref={question11Ref}
                                    name="question1"
                                    onBlur={handleBlur}
                                    className="form-control"
                                    placeholder=" eg. Simple to
                                    use"
                                  />
                                </div>
                              </div>
                              <div className="question-blk">
                                <h2>
                                  When above this feature is implemented, I’m
                                  able to do the following…
                                </h2>
                                <div className="question-fields p-relative">
                                  <input
                                    value={values?.question2}
                                    onChange={handleChange}
                                    type="text"
                                    name="question2"
                                    className="form-control"
                                    ref={question22Ref}
                                    onBlur={handleBlur}
                                    placeholder="eg. I
                                    can record my journal entries using
                                    video or audioAdd Answer"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-12">
                              <div className="select-feature-blk">
                                <h2 className="modal-heading">
                                  What existing feature(s) are you most excited
                                  about?
                                </h2>

                                <div className="featureslist">
                                  {FeaturesArray.map((item, index) => (
                                    <div
                                      key={index}
                                      className="features-list-item"
                                    >
                                      <input
                                        type="checkbox"
                                        id={`feature-${item.id}`}
                                        onChange={(e) =>
                                          handleFeatureChange(
                                            item.title,
                                            e.target.checked
                                          )
                                        }
                                        checked={
                                          Array.isArray(
                                            values?.selectedFeatures
                                          ) &&
                                          values.selectedFeatures?.includes(
                                            item?.title
                                          )
                                        }
                                      />
                                      <label htmlFor={`feature-${item.id}`}>
                                        <span className="feature-check" />
                                        <span className="feature-heading">
                                          {item.title}
                                        </span>
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <h2 className="modal-heading mt-1">
                                Share any additional features needed to meet
                                your specific intended use...
                              </h2>
                            </div>
                            <div className="col-lg-12">
                              <div className="append-block">
                                <ul className="appendlist-wrapper">
                                  {Array.isArray(values?.otherFeatures) &&
                                    values?.otherFeatures?.map(
                                      (item, index) => (
                                        <li key={index} className="appendlist">
                                          <span className="addedfeatuire">
                                            {item?.name}
                                          </span>
                                          <button
                                            type="button"
                                            className="removefeature"
                                          >
                                            <i
                                              onClick={() => removeItem(index)}
                                              className="fa-solid fa-xmark"
                                            />
                                          </button>
                                        </li>
                                      )
                                    )}
                                </ul>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="signup-fields p-relative">
                                <input
                                  value={chipData}
                                  type="text"
                                  className="form-control"
                                  id="others"
                                  placeholder="List additional features needed to capture your life story"
                                  onChange={handleChipChange}
                                  maxLength={90}
                                  onBlur={handleBlur}
                                  onKeyDown={handleEnterPress}
                                />
                                <button
                                  className="addfeatures"
                                  type="button"
                                  onClick={addChipData}
                                >
                                  +Add
                                </button>
                              </div>
                              {errors?.otherFeatures &&
                                touched?.otherFeatures && (
                                  <p className="error pt-0 px-2">
                                    {errors.otherFeatures}
                                  </p>
                                )}
                              <span className="text-title">
                                Max length(90 words)
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="signup-form-btn">
                          <button
                            onClick={handleSubmit}
                            type="button"
                            className="signup-submit-btn"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaitLiastModal;
